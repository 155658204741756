
$.material.init();

var touch = Modernizr.touch;
var mobile = false;
var $window = $(window);
var $animation_elements = $('.animation-element');



// Used to compensante Chrome/Safari bug (they don't care about scroll bar for width)
function scrollCompensate() {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 === w2) { w2 = outer.clientWidth; }

    document.body.removeChild(outer);

    return (w1 - w2);
}

// Resize calculs
function resize() {
    if (mobile == false) {
        if ($(window).width() + scrollCompensate() < 740) {


            mobile=true;
        } else {

            //$('body').removeClass('o-hidden');
        }

    } else {
        if ($(window).width() + scrollCompensate() >= 740) {

            //$('body').removeClass('o-hidden');
            mobile = false;
        } else {

        }
    }

}


// Animation elements
$window.on('scroll resize', function () {
    check_if_in_view();
});
$window.trigger('scroll');

function check_if_in_view() {
    $elements = $(window);
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        //var element_bottom_position = (element_top_position + element_height);

        /*check to see if this current container is within viewport
        if (element_top_position < window_bottom_position) {
            $element.addClass('in-view');
            if ($element.hasClass('box-scene')){
                $('.in-view .num-progresive').each(function() {
                    counter($(this));
                });
            }
        } */
    });
}

  

$(function () {
    // Controlar si ha saltado version mobile
    if ($(window).width() + scrollCompensate() < 740) {
        mobile = true;
    }

    // Porgramación para abrir cualquier enlace con data-toogle="lightbox" en modal
    $(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    if ($('.aside-right .list-prop').length > 0){
        $('.aside-right .list-prop').height(($('.content-left').height()-170)+'px');
    }

    resize();

});

    $(document).on("click", "#btn-menu", function () {
        if ($('.header-menu').hasClass('open')) {
            $('.header-menu').removeClass('open');
            $(this).removeClass('active');
            $('body').removeClass('o-hidden');
        } else {
            $('#collapseLog').removeClass('open');
            $('.header-menu').addClass('open');
            $(this).addClass('active');
            $('#btn-log').removeClass('active');
            $('body').addClass('o-hidden');
        }
    }).on('click', '#btn-log', function () {
        if ($('#collapseLog').hasClass('open')) {
            $('#collapseLog').removeClass('open');
            $(this).removeClass('active');
            $('body').removeClass('o-hidden');
        } else {
            $('.header-menu').removeClass('open');
            $('#collapseLog').addClass('open');
            $(this).addClass('active');
            $('#btn-menu').removeClass('active');
            $('body').addClass('o-hidden');
        }
    });


$(window).resize(function () {
    resize();
});