function utf8_to_b64(str) {
	return window.btoa(encodeURIComponent(escape(str)));
}

function b64_to_utf8(str) {
	str = str.replace(/\s/g, '');
	return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

$(document).ready(function () {
	var offLink,okLink;
	$('.offLink').click(function(){
		offLink = $(this).data('link');
		okLink = b64_to_utf8(offLink).toString();
		okLink = okLink.replace("__i_n_t__","?");
		window.open(okLink, '_blank');
	});
	$('.offPopup').click(function(){
		offLink = $(this).data('link');
		okLink = b64_to_utf8(offLink).toString();
		okLink = okLink.replace("__i_n_t__","?");
		$('.offPopup').fancybox({
		    'type' : 'iframe',
			'maxWidth' : 750,
			'maxHeight' : 600,
			'href' : okLink,
			helpers: { 
		        title: null
		    },
		    tpl:{
				closeBtn : '<a id="cerrarFancy" class="fancybox-item fancybox-close" href="#"><span class="text-hide">Cerrar</span></a>'
		    },
			afterShow: function () {
		        $('.fancybox-wrap').attr("aria-hidden",false);
		        $('#wrapper, #aside-mobile').attr("aria-hidden",true);
		        $(this.content).attr("tabindex",-1);
		        fancyIn = true;
		    },
			afterClose: function () {
		        $('.fancybox-wrap').attr("aria-hidden",true);
		        $('#wrapper, #aside-mobile').attr("aria-hidden",false);
		        $(this.content).attr("tabindex",0);
		        fancyIn = false;
		    }    
		});
	});

});